// eslint-disable-next-line no-unused-vars
import { all, takeEvery, takeLatest, take, put, call, select } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { notification } from 'antd'
import { history } from 'App'
// import { push } from 'react-router-redux'
import _ from 'lodash'
import * as firebase from 'services/firebase'
// import { USER } from 'constant'
import PATH from 'path.config'
import { fireStore } from '../../firebase'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
    updateUser: firebase.updateUser,
    deleteUser: firebase.deleteUser,
    sendFindPasswordMail: firebase.sendFindPasswordMail,
    sendBeacon: firebase.sendBeacon,
    getSchedules: firebase.getSchedules,
  },
}
// eslint-disable-next-line no-unused-vars
const delay = time => new Promise(resolve => setTimeout(resolve, time))

// const redirectTo = location => {
//   history.push(location)
// }

export function* LOGIN({ payload }) {
  const { email, password, redirect } = payload
  // console.log(payload)
  let loginRedirectPath = PATH.HOME
  if (redirect) {
    loginRedirectPath = redirect
  }
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].login, email, password)
  // console.log(success);

  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    // yield put({
    //   type: 'user/SET_STATE',
    //   payload: {
    //     authorized: true,
    //   },
    // })

    yield history.push(loginRedirectPath)

    notification.success({
      message: 'Signed in',
      description: 'Welcome to Tech seminar 2021',
      duration: 2,
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* REGISTER({ payload }) {
  // eslint-disable-next-line no-unused-vars
  const { email, password, ...data } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      authorized: false,
    },
  })

  yield history.push(PATH.AUTH.REGISTER_COMPLETE)

  // yield put(push(PATH.AUTH.REGISTER_COMPLETE))s
  delete data.confirm
  data.allowed = true
  data.surveyComplete = false
  const { authProvider } = yield select(state => state.settings)
  const { defaultAllowedSchedules, defaultUserSchedule } = yield select(
    state => state.Project.settings,
  )
  data.acceptableSchedules = defaultAllowedSchedules || []
  data.defaultSchedule = defaultUserSchedule || null
  if (data.schedule) {
    const schedules = yield call(mapAuthProviders[authProvider].getSchedules)
    if (_.has(schedules, data.schedule)) {
      data.acceptableSchedules = [data.schedule]
      data.defaultSchedule = data.schedule
    }
    delete data.schedule
  }

  // eslint-disable-next-line no-unreachable
  const success = yield call(mapAuthProviders[authProvider].register, email, password, data)

  // yield call(delay, 2000)
  // const success = true
  if (success) {
    // yield history.push(PATH.AUTH.REGISTER_COMPLETE)
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        authorized: true,
      },
    })
    notification.success({
      message: 'Sign up complete',
      description: 'You has been successfully applied for a Seminar.',
      duration: 2,
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* USER_EDIT({ payload }) {
  // console.log('USER_EDIT', payload)
  // const { email, name, nickname, phone, department, work, attendance, type, role } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  // console.log(mapAuthProviders[authProvider].updateUser)

  const success = yield call(mapAuthProviders[authProvider].updateUser, payload)

  if (success) {
    notification.success({
      message: '수정 완료.',
      description: '참가자 정보가 수정되었습니다.',
      duration: 1,
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}
export function* USER_DELETE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  // console.log(mapAuthProviders[authProvider].updateUser)
  const success = yield call(mapAuthProviders[authProvider].deleteUser, payload)
  if (success) {
    notification.success({
      message: '삭제 완료.',
      description: '참가자가 삭제되었습니다.',
      duration: 1,
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  // console.log('load currentuser')
  const { authProvider } = yield select(state => state.settings)
  const user = yield call(mapAuthProviders[authProvider].currentAccount)

  // console.log('sagas', user)
  if (user) {
    const ref = fireStore.collection('users').doc(user.uid)
    const channel = eventChannel(emit => ref.onSnapshot(emit))

    try {
      while (true) {
        const profileData = yield take(channel)
        // console.log(profileData.data())
        const {
          role,
          email,
          profile,
          allowed,
          surveyComplete,
          blocked,
          loginToken,
          acceptableSchedules,
          defaultSchedule,
        } = profileData.data()

        if (blocked === true) {
          channel.close()
          notification.error({
            message: 'Access Denied.',
            description: 'You have blocked to access this service.',
            duration: 5,
          })
          yield put({
            type: 'user/LOGOUT',
          })
        } else {
          const userData = {
            id: user.uid,
            role,
            email,
            surveyComplete: surveyComplete || false,
            profile,
            authorized: true,
            blocked: blocked || false,
            loginToken: loginToken || null,
            allowed: allowed || false,
            acceptableSchedules: acceptableSchedules || [],
            defaultSchedule: defaultSchedule || null,
          }
          yield put({
            type: 'user/SET_STATE',
            payload: userData,
          })
        }
      }
    } catch (_error) {
      // console.log(error)
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  // console.log('logout saga')
  const { authProvider } = yield select(state => state.settings)
  const result = yield call(mapAuthProviders[authProvider].logout)
  if (result === true) {
    // console.log('success logout')
    const initialUser = {
      type: 'user/SET_STATE',
      payload: {
        id: '',
        profile: {},
        role: '',
        email: '',
        allowed: false,
        surveyComplete: false,
        authorized: false,
        loading: false,
        blocked: false,
        loginToken: '',
        acceptableSchedules: null,
        defaultSchedule: null,
      },
    }
    yield put(initialUser)
    // try {
    //   const data = yield call(rsFirebase.auth.signOut)
    // } catch (error) {
    //   yield put(initialUser)
    // }

    // console.log('logout')

    yield history.push(PATH.HOME)
  }
}

export function* FORGOT_PASSWORD({ payload }) {
  const { authProvider } = yield select(state => state.settings)
  // console.log(payload)
  const result = yield call(mapAuthProviders[authProvider].sendFindPasswordMail, payload.email)
  // console.log(result)
  if (result) {
    yield history.push(PATH.AUTH_LOGIN)
    notification.success({
      message: 'Password reset mail has been sent',
      description: 'Please check your email and use the link in email to reset your password',
      duration: 3,
    })
  } else {
    notification.error({
      message: 'Send mail error',
      description: 'Email address does not exist',
      duration: 3,
    })
  }
}

export function* SEND_BEACON({ payload }) {
  const { authProvider } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProvider].sendBeacon, payload.path)
}

export function* SET_PAGE({ payload }) {
  const { authProvider } = yield select(state => state.settings)
  if (payload.user && payload.user.authorized && payload.user.id && payload.location) {
    const data = {
      id: payload.user.id,
      values: { lastOnPage: payload.location.pathname || null },
    }
    yield call(mapAuthProviders[authProvider].updateUser, data)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.USER_DELETE, USER_DELETE),
    takeEvery(actions.USER_EDIT, USER_EDIT),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.BEACON, SEND_BEACON),
    takeEvery(actions.SET_PAGE, SET_PAGE),

    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
