/* eslint-disable no-unused-vars */
import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'

import { history } from 'App'
import * as firebase from 'services/firebase'
// import { USER } from 'constant'
import PATH from 'path.config'
// import { history } from 'App'
import { rsFirebase, fireBase } from '../../firebase'
// import { USER, PATH } from 'constant'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    updateUser: firebase.updateUser,
  },
}

export function* ADD({ payload }) {
  const user = yield select(state => state.user)
  if (user.surveyComplete === false) {
    // console.log(user)
    const data = {
      user,
      ...payload,
      // order: 999999,
      createdAt: fireBase.firestore.Timestamp.now(),
    }
    // console.log(data)
    yield call(rsFirebase.firestore.addDocument, 'surveyResult', data)

    const { authProvider } = yield select(state => state.settings)
    const userSurveyCompleteData = {
      id: user.id,
      values: {
        surveyComplete: true,
      },
    }
    const success = yield call(mapAuthProviders[authProvider].updateUser, userSurveyCompleteData)

    notification.success({
      message: 'Survey Complete',
      description: 'Thank you for taking the time to complete survey.',
      duration: 2,
    })
  } else {
    notification.error({
      message: 'You are already complete',
      description: 'You aleady finished this survey',
      duration: 2,
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      surveyComplete: true,
    },
  })

  yield history.push(PATH.SURVEY_COMPLETE)
}

export default function* rootSaga() {
  yield all([takeEvery(actions.ADD, ADD)])
}
