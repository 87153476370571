import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Menu, Modal } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import store from 'store'
import { find } from 'lodash'
// import { TEXT } from 'constant'
import PATH from 'path.config'
import { FormattedMessage } from 'react-intl'
import style from './style.module.scss'

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: menu.menuData,
  logo: settings.logo,
  menuColor: settings.menuColor,
  role: user.role,
  user: user,
})

const { confirm } = Modal
const MenuTop = ({
  menuData = [],
  location: { pathname },
  user,
  // menuColor,
  // logo,
  transparent,
  role,
}) => {
  const dispatch = useDispatch()
  // dispatch({
  //   type: 'settings/RESET_SETTINGS',
  // })
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])

  useEffect(() => {
    applySelectedKeys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData])

  const applySelectedKeys = () => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname])
    setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  }

  const handleClick = e => {
    store.set('app.menu.selectedKeys', [e.key])
    setSelectedKeys([e.key])
  }

  const logout = e => {
    e.preventDefault()
    if (user.surveyComplete) {
      dispatch({
        type: 'user/LOGOUT',
      })
    } else {
      confirm({
        title: 'Sign out',
        content: "You didn't finished the survey. Are you sure you want to sign out?",
        okText: 'Sign out',
        cancelText: 'Cancel',

        onOk() {
          dispatch({
            type: 'user/LOGOUT',
          })
        },
        // onCancel() {
        //   setVod(initialVODState)
        // },
      })
    }
  }

  const generateMenuItems = () => {
    const generateItem = item => {
      const { key, title, url, icon, disabled, count } = item
      if (item.category) {
        return null
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled} className={`${style.mainMenuItem}`}>
            {item.target && (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {icon && <span className={`${icon} ${style.icon}`} />}
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
              </a>
            )}
            {!item.target && (
              <Link to={url}>
                {icon && <span className={`${icon} ${style.icon}`} />}
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
              </Link>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          {icon && <span className={`${icon} ${style.icon}`} />}
          <span className={style.title}>{title}</span>
          {count && <span className="badge badge-success ml-2">{count}</span>}
        </Menu.Item>
      )
    }
    const generateSubmenu = items =>
      items.map(menuItem => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
              <span className={style.title}>{menuItem.title}</span>

              {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
            </span>
          )
          return (
            <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </Menu.SubMenu>
          )
        }
        return generateItem(menuItem)
      })
    return menuData.map(menuItem => {
      if (menuItem.roles && !menuItem.roles.includes(role)) {
        return null
      }
      if (menuItem.open === 'user' && !user.authorized) {
        return null
      }
      if (menuItem.open === 'guest' && user.authorized) {
        return null
      }
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key} className={`${style.parentTitle}`}>
            {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
            <span className={style.title}>{menuItem.title}</span>
            <i className={`${style.title} fe fe-chevron-down ml-1 font-size-18`} />
            {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
          </span>
        )
        return (
          <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  // eslint-disable-next-line no-unused-vars
  const renderUserMenu = () => {
    if (!user.authorized) {
      return (
        <div className={style.authContainer}>
          <Link to={PATH.AUTH.LOGIN} className={`${style.button} d-inline`}>
            <FormattedMessage id="topBar.profileMenu.signIn" />
          </Link>
        </div>
      )
    }
    return (
      <div className={style.authContainer}>
        {/* <Link to={PATH.MYPAGE} className={`${style.highlight} d-md-inline d-none`}>
          <FormattedMessage id="topBar.profileMenu.myPage" />
        </Link> */}
        {/* <span className={`${style.highlight} d-md-inline d-none`}> | </span> */}
        <a href="#" onClick={logout} className={`${style.button} d-md-inline d-none`}>
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
        <a href="#" onClick={logout} className={`${style.button} d-inline d-md-none`}>
          <i className="fe fe-log-out" />
        </a>
      </div>
    )
  }
  // eslint-disable-next-line no-unused-vars
  const siteLogoColor = transparent ? 'white' : 'black'

  return (
    <div className={classNames(`${style.menu}`, { [style.dark]: !transparent })}>
      <div className={`${style.menuContainer}`}>
        <div className={style.logoContainer}>
          <div className={style.logo}>
            <div className={style.name}>
              <Link to={PATH.HOME} className="text-white">
                <img
                  src={`/resources/images/samsung/neo-qled-seminar-2021-logo-${siteLogoColor}.svg`}
                  // src="/resources/images/samsung/seminar-logo-black.svg"
                  alt="samsung logo"
                  className={`${style.logoGraphic}`}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className={style.navigation}>
          <Menu onClick={handleClick} selectedKeys={selectedKeys} mode="horizontal">
            {generateMenuItems()}
          </Menu>
        </div>
        {renderUserMenu()}
      </div>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MenuTop))
