// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
// import { TEXT } from 'constant'
import classNames from 'classnames'
import TopBar from 'components/UI/layout/OpenTopBar'
import Breadcrumbs from 'components/UI/layout/Breadcrumbs'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
})

const PublicLayout = ({
  children,
  isTopbarFixed,
  isGrayTopbar,
  // isContentMaxWidth,
  // isAppMaxWidth,
  // isGrayBackground,
  // isSquaredBorders,
  // isCardShadow,
  // isBorderless,
}) => {
  return (
    <div>
      <Layout>
        <Layout.Header
          className={classNames('cui__layout__header', {
            cui__layout__fixedHeader: isTopbarFixed,
            cui__layout__headerGray: isGrayTopbar,
          })}
        >
          <TopBar />
        </Layout.Header>
        <Breadcrumbs />
        <Layout.Content style={{ height: '100%', position: 'relative' }}>
          <div className={`${style.base}`} style={{ marginTop: '36px' }}>
            {children}
          </div>
          <div className={`${style.bottombar} mt-auto pb-5 pt-5 d-block`}>
            <div className={`${style.bottomText} text-center text-md-right`}>
              <span className="font-weight-normal">충남대학교병원 | </span>
              <Link to="/" target="_blank">
                <img
                  src="/resources/images/cnuh/pageone-livepack.svg"
                  alt="페이지원 라이브팩"
                  className="mw-100"
                />
              </Link>
            </div>
          </div>
        </Layout.Content>
      </Layout>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(PublicLayout))
