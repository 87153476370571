import { all, takeEvery, call } from 'redux-saga/effects'
// import { notification } from 'antd'
// import { history } from 'App'
// import * as firebase from 'services/firebase'
// import * as firebase from 'services/firebase'
// import moment from 'moment'
// import { deactivate, currentSession } from 'services/firebase'
// import _ from 'lsodash'
import { rsFirebase } from '../../firebase'
// import { USER, PATH } from 'constant'
import actions from './actions'

const documentName = 'project'
export function* SET_SETTINGS({ payload }) {
  const fieldId = 'settings'
  const data = {
    ...payload,
  }
  yield call(rsFirebase.firestore.setDocument, `${documentName}/${fieldId}`, data, { merge: true })
}

export function* GET_SETTINGS() {
  const fieldId = 'settings'

  const successActionCreator = doc => {
    // console.log(doc)
    let settings = {}
    if (doc && doc.exists) {
      settings = doc.data()
    }

    // console.log('successActionCreator')
    return {
      type: actions.SET_STATE,
      payload: { settings: settings },
    }
  }

  yield call(rsFirebase.firestore.syncDocument, `${documentName}/${fieldId}`, {
    successActionCreator,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_SETTINGS, SET_SETTINGS),
    takeEvery(actions.GET_SETTINGS, GET_SETTINGS),
    GET_SETTINGS(),
  ])
}
