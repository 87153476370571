/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
// import Loader from 'components/cleanui/layout/Loader'
import { TEXT } from 'constant'
import PATH from 'path.config'
import MainLayout from './Main'
import AuthLayout from './Auth'
import AdminLayout from './Admin'
import PublicLayout from './Public'
import OpenLayout from './Open'

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  admin: AdminLayout,
  main: MainLayout,
  open: OpenLayout,
}

const mapStateToProps = ({ user }) => ({ user })
let previousPath = ''

const Layout = ({ user, children, location: { pathname, search } }) => {
  // NProgress & ScrollTop Management
  const [transparent, setTransparent] = useState(false)

  NProgress.configure({ showSpinner: false })

  useEffect(() => {
    let nextTransparent = false
    const currentPath = pathname + search

    if (
      pathname === PATH.WELCOME ||
      pathname === PATH.HOME ||
      /^\/auth(?=\/|$)/i.test(pathname) ||
      pathname === PATH.AUTH.REGISTER_COMPLETE ||
      pathname === PATH.AUTH.REGISTER_SHORT
    ) {
      nextTransparent = true
    } else {
      nextTransparent = false
    }
    if (currentPath !== previousPath) {
      window.scrollTo(0, 0)
      NProgress.start()
      setTransparent(true)

      // if (pathname === PATH.WELCOME || pathname === PATH.HOME) {
      //   isTransparent = true
      // } else {
      //   isTransparent = false
      // }
    }
    setTimeout(() => {
      NProgress.done()
      previousPath = currentPath
      setTimeout(() => {
        setTransparent(nextTransparent)
      }, 300)

      // setTransparent(lastTransparent)
    }, 300)
  }, [pathname, search])

  // Layout Rendering
  const getLayout = () => {
    // if (pathname === PATH.PUBLIC.OPEN_STREAMING || pathname === PATH.PUBLIC.CHINA_STREAMING) {
    //   return 'publicCustom'
    // }

    if (/^\/open(?=\/|$)/i.test(pathname)) {
      return 'open'
    }
    if (
      /^\/auth(?=\/|$)/i.test(pathname) ||
      pathname === PATH.AUTH.REGISTER_COMPLETE ||
      pathname === PATH.AUTH.REGISTER_SHORT
    ) {
      return 'main'
    }
    // if (pathname === PATH.PUBLIC.INTRODUCE || pathname === PATH.PUBLIC.PROGRAMS) {
    //   return 'authFullWidth'
    // }

    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = user.authorized
  const isUserLoading = user.loading
  const isAuthLayout = getLayout() === 'auth'
  const isPublicLayout = getLayout() === 'public'
  const isOpenLayout = getLayout() === 'open'

  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }
    if (isPublicLayout) {
      return <Container>{children}</Container>
    }

    if (isOpenLayout) {
      return <Container>{children}</Container>
    }
    // if (!isUserAdmin && isAdminLayout) {
    //   return <Redirect to={PATH.HOME} />
    // }
    // // redirect to login page if current is not login page and user not authorized
    // if (!isAuthLayout && !isUserAuthorized) {
    //   return <Redirect to={PATH.AUTH.LOGIN} />
    // }

    // in other case render previously set layout
    return <Container transparent={transparent}>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate={`${TEXT.TITLE.TITLE} | %s`} title="" />
      {BootstrappedLayout()}
    </Fragment>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
