import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import sessions from './session/reducers'
import vod from './vod/reducers'
import survey from './survey/reducers'
import userProfileField from './userProfileField/reducers'
import chat from './chat/reducers'
import logger from './Logger/reducers'
import Project from './Project/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    sessions,
    vod,
    survey,
    userProfileField,
    chat,
    logger,
    Project,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
  })
