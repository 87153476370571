import { all } from 'redux-saga/effects'
// import { history } from 'App'
// import * as firebase from 'services/firebase'

// import moment from 'moment'
// import { rsFirebase, fireBase } from '../../firebase'
// import { USER, PATH } from 'constant'
// import actions from './actions'

export default function* rootSaga() {
  yield all([
    // takeEvery(actions.ADD, ADD),
    // takeEvery(actions.DELETE, DELETE),
    // takeEvery(actions.UPDATE, UPDATE),
    // takeEvery(actions.GET_LIST, GET_LIST),
    // takeEvery(actions.GET_DATA, GET_DATA),
    // takeEvery(actions.ENABLE_QNA, ENABLE_QNA),
    // takeEvery(actions.ACTIVATE, ACTIVATE),
    // takeEvery(actions.CURRENT, CURRENT),
    // takeEvery(actions.LIVE_STATE, LIVE_STATE),
  ])
}
