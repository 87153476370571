const actions = {
  SET_STATE: 'vod/SET_STATE',
  GET_DATA: 'vod/GET_DATA',
  GET_LIST: 'vod/GET_LIST',
  ADD: 'vod/ADD',
  UPDATE: 'vod/UPDATE',
  DELETE: 'vod/DELETE',
  ACTIVATE: 'vod/ACTIVATE',
  ENABLE_QNA: 'vod/ENABLE_QNA',
  CURRENT: 'vod/CURRENT',
}

export default actions
