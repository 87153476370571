import React from 'react'
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

// import { FormattedMessage } from 'react-intl'
// import { Button } frsom 'antd'
// import { Badge } from 'antd'
// import { TEXT } from 'constant'
import style from './style.module.scss'

const mapStateToProps = ({ user, sessions }) => ({ user, sessions })

// const TopBar = ({ dispatch, user }) => {
const TopBar = () => {
  // console.log(sessions)

  const location = useLocation()
  console.log(location.pathname)
  const channelAClassName = location.pathname === '/open/a' ? 'text-white' : 'text-gray-6'
  const channelBClassName = location.pathname === '/open/b' ? 'text-white' : 'text-gray-6'

  console.log(channelAClassName)
  console.log(channelBClassName)
  return (
    <div className={style.topbar}>
      <div className="mr-4 d-none d-lg-block">
        <Link to="/open" className={`${style.title}`}>
          <span className="font-weight-bold">2020 바이오헬스 공동연구 심포지엄</span>
        </Link>
      </div>
      <div className="mr-4 d-lg-none d-block">
        <Link to="/open" className={`${style.title}`}>
          <span className="fe fe-home"> </span>
        </Link>
      </div>
      <div className="mr-auto d-sm-block d-none" />
      <div className="d-md-block">
        {/* <Badge count='LIVE' offset={[-15, -8]} size="small"> */}
        <Link className={`${style.normal} ${channelAClassName}`} to="/open/a">
          바이오헬스 공동연구 발표회
        </Link>
        {/* </Badge> */}
      </div>
      <div className="mr-3 ml-3 d-block">|</div>
      <div className="mr-4 d-md-block">
        {/* <Badge count='LIVE' offset={[-15, -8]} size="small"> */}
        <Link className={`${style.normal} ${channelBClassName}`} to="/open/b">
          혁신형 의사과학자 공동연구사업 성과발표회
        </Link>
        {/* </Badge> */}
      </div>
      {/* <div className="mr-4 d-md-block">
        <Link className={`${style.normal}`} to="/location">
          오시는길
        </Link>
      </div> */}
      {/* <div className="mr-4 d-none d-md-block">소개</div> */}
    </div>
  )
}

export default connect(mapStateToProps)(TopBar)
