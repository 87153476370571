import PATH from 'path.config'

export default async function getMenuData() {
  return [
    // {
    //   title: 'Programs',
    //   key: 'programs',
    //   url: PATH.PROGRAMS,
    //   open: 'public',
    // },
    {
      title: 'Menu',
      key: 'menu',
      children: [
        {
          title: 'Home',
          key: 'home',
          // url: PATH.LIVE,
          url: PATH.HOME,
          open: 'public',
        },
        {
          title: 'Seminar',
          key: 'live',
          // url: PATH.LIVE,
          url: PATH.LIVE,
          open: 'public',
        },
        {
          title: 'Support',
          key: 'support',
          url: PATH.SUPPORT,
          open: 'public',
        },
        {
          title: 'MY PAGE',
          key: 'mypage',
          url: PATH.MYPAGE,
          open: 'user',
        },
      ],
    },

    // {
    //   title: 'Sign in',
    //   key: 'signin',
    //   url: PATH.AUTH.LOGIN,
    //   open: 'guest',
    // },

    // {
    //   title: 'Sign out',
    //   key: 'signout',
    //   url: PATH.AUTH.LOGOUT,
    //   open: 'user',
    // },
    // {
    //   title: '참가하기',
    //   key: 'register',
    //   // url: PATH.LIVE,
    //   url: PATH.AUTH.REGISTER,
    //   open: 'guest',
    // },

    // {
    //   title: '오시는길',
    //   key: 'location',
    //   url: PATH.LOCATION,
    //   open: 'public',
    // },

    // {
    //   title: '문의하기',
    //   key: 'question',
    //   url: PATH.QUESTION,
    // },
  ]
}
