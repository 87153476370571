import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// eslint-disable-next-line no-unused-vars
import { FormattedMessage } from 'react-intl'
// import { Button } frsom 'antd'
// import { Badge } from 'antd'
import PATH from 'path.config'
// import { TEXT } from 'constant'
import style from './style.module.scss'

const mapStateToProps = ({ user, sessions }) => ({ user, sessions })

const TopBar = ({ dispatch, user }) => {
  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  return (
    <div className={`${style.topbar}`}>
      <div className="mr-4 ml-5">
        <Link to={PATH.HOME} className="text-white">
          <img
            src="/resources/images/samsung/logo-samsung-white.svg"
            alt="samsung logo"
            className={`${style.logo}`}
          />
        </Link>
      </div>

      {/* <div className="mr-auto d-sm-block d-none" /> */}
      {/* <div className="mr-4 d-none d-md-block">
        <span className="text-primary">{user.name}</span> 님 환영합니다.
      </div> */}
      <div className="mb-0 ml-auto ml-md-0 font-size-16">
        {/* <Link to="/mypage" className={`${style.highlight} d-md-inline d-none`}>
          내 정보
        </Link> */}
        {user.authorized ? (
          <>
            <span className={`${style.highlight} d-md-inline d-none`}> | </span>
            <a href="#" onClick={logout} className={`${style.highlight} d-md-inline d-none`}>
              <FormattedMessage id="topBar.profileMenu.logout" />
            </a>
            <a href="#" onClick={logout} className={`${style.highlight} d-inline d-md-none`}>
              {/* <i className="fe fe-log-out" /> */}
              <FormattedMessage id="topBar.profileMenu.logout" />
            </a>
          </>
        ) : (
          <Link to={PATH.AUTH.LOGIN} className={`${style.highlight} d-inline d-md-none`}>
            <FormattedMessage id="topBar.profileMenu.signIn" />
          </Link>
        )}
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(TopBar)
