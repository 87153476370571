// import 'react-app-polyfill/ie11'
// import 'react-app-polyfill/stable'

import 'antd/lib/style/index.less' // antd core styles
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles third-party component styles

import React from 'react'
import ReactDOM from 'react-dom'
import { createHashHistory } from 'history'
// import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { Provider } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'

import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Localization from './localization'
import Router from './router'
import * as serviceWorker from './serviceWorker'
import { fireBase } from './firebase'
// mocking api
// import 'services/axios/fakeApi'

// middlewared
// const history = createHashHistory()
// const rfConfig = {}

// const history = createBrowserHistory()
const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
if (process.env.NODE_ENV === 'development') {
  // middlewares.push(logger)
}
if (process.env.NODE_ENV !== 'development') {
  console.log(
    '%cPageone 개발팀',
    'color: Blue;background:white; font-family: sans-serif; font-size: 4.5em; font-weight: bolder; text-shadow: #000 1px 1px 1px 1px;',
  )
  console.log(
    '%c궁금한점 있으시면 연락주세요',
    'color: yellow; font-family: sans-serif; font-size: 2.5em; font-weight: bolder; text-shadow: #000 1px 1px;',
  )
  console.log(
    '%chttp://github.com/juniac',
    'color: white; font-family: sans-serif; font-size: 2.5em; font-weight: bolder; text-shadow: #000 1px 1px;',
  )
  console.log(
    '%c===============',
    'color: white; font-family: sans-serif; font-size: 2.5em; font-weight: bolder; text-shadow: #000 1px 1px;',
  )
}
// const createStoreWithFirebase = compose(
//   reduxFirestore(fireBase, { rfConfig }), // firebase instance as first argument, rfConfig as optional second
// )(createStore)

// const store = createStoreWithFirebase(reducers(history), compose(applyMiddleware(...middlewares)))

// eslint-disable-next-line spaced-comment

// eslint-disable-next-line import/no-mutable-exports

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
})

// eslint-disable-next-line import/no-mutable-exports
let store = null
if (process.env.NODE_ENV !== 'development') {
  // middlewares.push(logger)
  store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
} else {
  store = createStore(reducers(history), composeEnhancers(applyMiddleware(...middlewares)))
}

sagaMiddleware.run(sagas)

const rrfConfig = {
  userProfile: 'users',
}
const rrfProps = {
  firebase: fireBase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
}

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <Localization>
        <Router history={history} />
      </Localization>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { store, history }
