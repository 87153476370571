import localeAntd from 'antd/es/locale/en_US'

const messages = {
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Search...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.signIn': 'Sign in',
  'topBar.profileMenu.signUp': 'Sign up',
  'topBar.profileMenu.myPage': 'MY PAGE',
  'topBar.profileMenu.logout': 'Sign out',

  'auth.signIn': 'Sign in',
  'auth.signUp': 'Sign up',
  'auth.register': 'Register',
  'auth.forgotPassword': 'Forgot Password',
  'user.email': 'Email',
  'user.password': 'Password',
  'user.confirmPassword': 'Confirm Password',
  'user.name': 'Name',
  'user.workplace': 'Workplace',
  'user.Department': 'Department',
  'user.media': 'Media',
  'user.mediaFull': 'Media Outlet / Company',
  'user.country': 'Country',
  'user.title': 'Title',
  'user.titleFull': 'Title / Role',
  'user.firstName': 'First Name',
  'user.lastName': 'Last Name',

  'message.requireEmail': 'Email is required as User ID',
  'message.validateEmail': 'Email must be valid Email address format',
  'message.requirePassword': 'Password is required',
  'message.validatePassword': 'Password must be at least 6 characters',
  'message.confirmPasswordNotMatch': 'The two passwords that you entered do not match',
  'message.requireMedia': 'Media outlet is required.',
  'message.requireTitle': 'Title is required.',
  'message.requireCountry': 'Country is required.',
  'message.countryRequired': 'Country is required',
  'message.forgotPassword': 'I forgot my Password',
  'message.rememberPassword': 'I know my Password',
  'message.requireFirstName': 'First Name is required',
  'message.requireLastName': 'Last Name is required',
  'message.sendPasswordResetMail': 'Send a reset password link to your email.',
  'message.inputSignupEmail': 'Signed up Email is required',
  'message.signupComplete': 'Thank you for Signing up.',
  'message.signinRequired': 'authorization is required',
  'message.signinRequiredPage': 'Signing in is required to access this page',

  'word.sendEmail': 'Send Email',
  'word.resetPassword': 'Reset Password',
  'word.save': 'Save',
  'word.ok': 'OK',
  'word.cancel': 'Cancel',
  'word.NDA': 'NDA',
  // 'word.NDAFull': 'Non-disclosure agreement',
  'word.NDAFull': 'Samsung Visual Display User Agreement',

  'chat.askInputForm': 'Input your question',
  'chat.title': 'Live Q&A',
  'chat.send': 'Send',

  'firebase.emailExists': 'email already exists',
  'firebase.invalidEmail': 'Invalid Email address.',
  'firebase.weekPassword': 'Week password',

  'page.title.welcome': 'Home',
  'page.title.support': 'Support',
  'page.title.seminar': 'Seminar',
  'page.title.signin': 'Sign in',
  'page.title.signup': 'Sign up',
  'page.title.forgotpassword': 'Forgot password',
  'page.title.survey': 'Survey',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
