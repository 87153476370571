import actions from './actions'

const initialState = {
  id: '',
  role: '',
  email: '',
  profile: {},
  allowed: false,
  surveyComplete: false,
  authorized: process.env.REACT_APP_AUTHENTICATED || false, // false is default value
  loading: false,
  blocked: false,
  loginToken: '',
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
