import localeAntd from 'antd/es/locale/ko_KR'

const messages = {
  title: '한양대학교 의학연구원 온라인 컨퍼런스',
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Search...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.role': '권한',
  'topBar.profileMenu.email': '이메일',
  'topBar.profileMenu.phone': '전화번호',
  'topBar.profileMenu.editProfile': '회원정보 수정',
  'topBar.profileMenu.logout': '로그아웃',
  'topBar.profileMenu.login': '로그인',
}

export default {
  locale: 'ko-KR',
  localeAntd,
  messages,
}
