// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { TEXT } from 'constant'
// import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
})

const PublicLayout = ({
  children,
  // isContentMaxWidth,
  // isAppMaxWidth,
  // isGrayBackground,
  // isSquaredBorders,
  // isCardShadow,
  // isBorderless,
}) => {
  return (
    <div>
      <Layout>
        <Layout.Content
          style={{ height: '100%', position: 'relative' }}
          className={`${style.welcomeContainer}`}
        >
          <div className={`cui__utils__content ${style.cuiContent}`}>{children}</div>
          <div className={`${style.bottombar} mt-auto pb-5 pt-5 d-block`}>
            <div className={`${style.bottomText} text-center text-md-right`}>{TEXT.COPYRIGHT}</div>
          </div>
        </Layout.Content>
      </Layout>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(PublicLayout))
