/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router'
// import { useFirestoreConnect } from 'react-redux-firebase'

const RouteTracer = () => {
  const { location } = useReactRouter()
  // console.log(location)
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  useEffect(() => {
    if (user && user.authorized && location) {
      const data = { user: user, location: location }
      // console.log(data)
      dispatch({ type: 'user/SET_PAGE', payload: data })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return null
}

export default RouteTracer
