import React from 'react'
import { Link } from 'react-router-dom'
import { TEXT } from 'constant'
import PATH from 'path.config'
import style from './style.module.scss'

const Footer = () => {
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <div className="row p-0">
          <div className="col-12 col-md-8 text-md-left text-center">
            <span className={`${style.logo} mr-1`}>{TEXT.TITLE.TITLE}</span>
            <br />
            <p className={`${style.copyright} mb-0`}>{TEXT.COPYRIGHT}</p>
          </div>
          <div className="col-12 col-md-4 pr-md-4 pr-0 pl-0 pt-3 pb-3 text-md-right text-center">
            {/* <a href="http://www.samsung.com/" target="_blank" rel="noopener noreferrer">
              <img
                src="/resources/images/samsung/logo-samsung-black.svg"
                alt="logo"
                className={`${style.corpLogo}`}
              />
            </a> */}
            <Link to={PATH.HOME}>
              <img
                src="/resources/images/samsung/logo-samsung-black.svg"
                alt="logo"
                className={`${style.corpLogo}`}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
