const PATH = {
  HOME: '/',
  WELCOME: '/welcome',
  LIVE: '/live',
  COUNTDOWN: '/countdown',
  VOD: '/video',
  SUPPORT: '/support',
  SURVEY: '/survey',
  SURVEY_COMPLETE: '/survey/thankyou',
  PROGRAMS: '/programs',
  MYPAGE: '/mypage',

  AUTH: {
    LOGIN: '/auth/signin',
    LOGOUT: '/auth/signout',
    REGISTER: '/auth/signup',
    REGISTER_SHORT: '/r',
    FORGOT_PASSWORD: '/auth/forgot-password',
    REGISTER_COMPLETE: '/auth/welcome',
  },

  SYSTEM_404: '/system/404',
}

export default PATH
